import Features from '../components/Features'
import { useRef } from 'react'
import Plans from '../components/Plans'
import Wrap from '../components/Wrap'
import P from '../components/P'
import Rates from '../components/Rates'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import Section from '../components/Section'
import Intro from '../components/Intro'
import Table from '../components/Table'
import Support from '../components/Support'
import Connect from '../components/Connect'
import Heading from '../components/Heading'

export default function Subscriptions() {
  const ratesRef = useRef(null)
  const supportRef = useRef(null)
  const tradePartnerRef = useRef(null)

  return (
    <>
      <Section large>
        <Menu />
      </Section>
      <Wrap>
        <Section>
          <Heading center type={1} size={1}>
            Abonnementen
          </Heading>
          <Intro>
            We zijn momenteel bezig met een herziening van onze abonnementsstructuur. 

            Hierdoor is de informatie over onze abonnementen tijdelijk niet beschikbaar.
          </Intro>
        </Section>
      </Wrap>
      <Footer />
    </>
  )
}
